import { hydrateRoot } from "react-dom/client";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { useEffect, startTransition } from "react";

const SENTRY_TRACING_DEFAULT_SAMPLE_RATE = 0.15;

if (ENV.FLY_CONTEXT === "production") {
  Sentry.init({
    allowUrls: [/https?:\/\/aiven\.io/],
    denyUrls: [
    // Errors from OneTrust should be ignored
    /scripttemplates\/otSDKStub\.js/,
    // Errors coming from google tag manager
    /www.googletagmanager.com\/gtm.js/],

    dsn: ENV.SENTRY_DSN,
    release: ENV.SENTRY_RELEASE,
    environment: ENV.FLY_CONTEXT || process.env.NODE_ENV,
    // Temporarily remove Transaction
    tracesSampleRate: SENTRY_TRACING_DEFAULT_SAMPLE_RATE,
    tracePropagationTargets: [],
    normalizeDepth: 10, // Should provide enough depth
    maxBreadcrumbs: 10, // No need for 99 breadcrumbs, 10 should already guide us to right place
    integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(
        useEffect,
        useLocation,
        useMatches
      )
    })],

    ignoreErrors: [
    // sanity
    "Error streaming dataset",
    "Error in body stream",
    "a is undefined",

    // tracking, cookie
    "Qualified",
    "Non-Error promise rejection captured with value:",
    "Illegal invocation",
    "Failed to fetch",
    "ezDenty is not defined",
    "moat_px is not defined",
    "googlefc is not defined",
    "__cmp is not defined",
    "apstagLOADED is not defined",
    "undefined is not an object (evaluating 'fn.bannerGroup.html')",
    "Cannot read properties of undefined (reading 'Domain')",
    "Cannot read properties of undefined (reading 'DomainData')",
    "Non-Error promise rejection captured with value: Object Not Found Matching Id:2",
    "Non-Error promise rejection captured with keys: isCanceled",
    /undefined is not an object \(evaluating 'r(?:\[\\?"\\\\@context\\\\?"\])?\\.toLowerCase'\)/,

    // bots are trying to access our sites with a weird url
    "Invalid Unicode",
    "Request aborted",
    "URIError: URI malformed",
    "TypeError: Could not parse content as FormData.",

    // greenhouse
    "t.htmlElement.contentWindow.postMessage",

    // Minified React error
    "Minified React error #425",
    "Minified React error #423",
    "Minified React error #418",
    "normalizeMarkupForTextOrAttribute(react-dom/cjs/react-dom.production)",
    "Gd(react-dom/cjs/react-dom.production)",
    "hydrateInstance",
    "completeUnitOfWork"]

    // ignoreTransactions: [
    // 	// regex match /*/bin/querybuilder.json.;%0aa.css
    // 	"//.*/bin/querybuilder.json.;%0aa.css/",

    // 	// feed & sitemap
    // 	"routes/blog.feed[.]xml",
    // 	"routes/changelog.feed[.]xml",
    // 	"routes/developer.feed[.]xml",
    // 	"routes/blog.feed-$categoryOrAuthor-posts[.]xml",
    // 	"routes/robots[.]txt",
    // 	"routes/sitemap[.]xml",
    // 	"routes/sitemap-$locale[.]xml",

    // 	// non user facing
    // 	"routes/healthcheck",
    // ],
  });
}

startTransition(() => {
  hydrateRoot(document, <RemixBrowser />);
});